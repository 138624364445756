import { useState } from 'react';
import { flatMap } from 'lodash';
import { IconType } from 'react-icons';
import {
  Text,
  Checkbox,
  Flex,
  Tag,
  Icon,
  Box,
  Collapse,
  useDisclosure,
} from '@chakra-ui/react';
import { allIcons, icons } from '../utils/Icon';
import { TAction } from './Form';
import { useGetSelectFromId } from '../../helpers';
import { colors, theme } from '../../theme';

export type TLeaf = {
  name: string;
  id: any;
  type?: string;
  childs?: TLeaf[];
  icon?: IconType | string;
  noSpread?: boolean;
};

type TLeafProps = {
  leafs: TLeaf[];
  selectedValues: Record<string, boolean>;
  onChange: (
    checkId: string,
    value: boolean,
    action: string,
    childs?: TLeaf[],
    noSpread?: boolean,
  ) => void;
  idx?: number;
  actions?: TAction[];
  parentIdProp?: any;
};

const Leafs = (props: TLeafProps) => {
  return (
    <>
      {props.leafs.map((leaf, leafIdx) => (
        <Leaf {...props} {...leaf} leafIdx={leafIdx} />
      ))}
    </>
  );
};

const Leaf = ({
  onChange,
  idx: idxProp = 0,
  actions,
  parentIdProp,
  name,
  id,
  childs,
  noSpread,
  type,
  icon,
  selectedValues,
}: TLeafProps & TLeaf & { leafIdx: number }) => {
  const getSelectFromId = useGetSelectFromId();
  const { isOpen, onToggle } = useDisclosure();

  const parentId = idxProp === 0 ? id : parentIdProp;

  return (
    <>
      <Flex borderBottomWidth={1} fontSize={theme.fontSize.small}>
        <Flex
          mr={3}
          flexGrow={1}
          backgroundColor={childs ? `${colors.main}.50` : ''}
          borderRadius={15}
          pl={3}
          my={1}
          _hover={{
            backgroundColor: childs ? `${colors.main}.100` : '',
          }}
          cursor={childs ? 'pointer' : 'auto'}
        >
          <Flex
            w='100%'
            p={0}
            alignItems='center'
            _hover={{ backgroundColor: 'none' }}
            onClick={() => {
              if (!childs) return;
              onToggle();
            }}
          >
            <Icon
              boxSize={7}
              as={
                allIcons[`${icon}`] ||
                icon ||
                (childs ? icons.page : icons.column)
              }
            />
            <Text p={2} textTransform='capitalize'>
              {getSelectFromId(name)?.label || name}
              {type === 'address' ? ' adresse' : ''}
            </Text>
            <Box flexGrow={1} />
            {childs && (
              <Icon
                boxSize={5}
                as={allIcons[`HiChevron${isOpen ? 'Down' : 'Up'}`]}
                mr={3}
              />
            )}
          </Flex>
        </Flex>
        <Flex>
          {actions?.map(({ level, title, ...action }) => {
            if (level !== undefined && level !== idxProp) return null;

            const allChilds = flatMap(childs, (c) => [
              c,
              ...(c.childs || []),
            ]).filter((f) => f);
            const filteredAllChilds = allChilds?.filter(
              (c) => selectedValues[`${c?.id}-${title}`],
            );

            return (
              <LeafAction
                {...action}
                isIndeterminate={
                  !noSpread &&
                  filteredAllChilds.length > 0 &&
                  filteredAllChilds.length < allChilds.length
                }
                isChecked={
                  (!noSpread &&
                    !!allChilds.length &&
                    filteredAllChilds.length === allChilds.length) ||
                  selectedValues[`${id}-${title}`]
                }
                onChange={(value: boolean) =>
                  onChange(
                    `${id}-${title}`,
                    value,
                    title || '',
                    childs,
                    noSpread,
                  )
                }
              />
            );
          })}
        </Flex>
      </Flex>
      {childs && (
        <Box pl={3}>
          <Collapse in={isOpen} animateOpacity>
            {isOpen && (
              <Leafs
                leafs={childs}
                onChange={onChange}
                idx={idxProp + 1}
                actions={actions}
                parentIdProp={parentId}
                selectedValues={selectedValues}
              />
            )}
          </Collapse>
        </Box>
      )}
    </>
  );
};

const LeafAction = ({
  onChange,
  label,
  isChecked,
  isIndeterminate,
}: TAction) => {
  return (
    <Tag m={2} p={2}>
      <Checkbox
        isIndeterminate={isIndeterminate}
        isChecked={isChecked}
        onChange={() => {
          onChange?.(!isChecked);
        }}
      >
        <Text fontSize={theme.fontSize.xSmall}>{label}</Text>
      </Checkbox>
    </Tag>
  );
};

type TTreeInput = {
  list: TLeaf[];
  actions: TAction[];
  selected?: any;
  onChange: (e: any) => void;
};

const childCheck = (
  childs: TLeaf[],
  action: string,
  final: any,
  value: boolean,
) => {
  childs?.forEach((child) => {
    final[`${child.id}-${action}`] = value;
    child.childs && childCheck(child.childs, action, final, value);
  });
};

const TreeInput = ({ list, actions, selected = {}, onChange }: TTreeInput) => {
  const [selectedValues, setSelectedValues] =
    useState<Record<string, any>>(selected);

  const toggleTree = (
    checkId: string,
    value: boolean,
    action: string,
    childs?: TLeaf[],
    noSpread?: boolean,
  ) => {
    const newValues = { ...selectedValues };

    newValues[checkId] = value;

    if (!noSpread) {
      childs?.length && childCheck(childs, action, newValues, value);
    }

    setSelectedValues(newValues);
    onChange(newValues);
  };

  return (
    <Leafs
      leafs={list}
      onChange={toggleTree}
      actions={actions}
      selectedValues={selectedValues}
    />
  );
};

export default TreeInput;
