import { ChakraProvider } from '@chakra-ui/react';
import { createRoot } from 'react-dom/client';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import App from './App';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import theme from './theme';

import './App.css';
import './index.css';

const domNode = document.getElementById('root');
const root = createRoot(domNode as HTMLElement);

root.render(
  <ChakraProvider theme={theme}>
    <DndProvider backend={HTML5Backend}>
      <App />
    </DndProvider>
  </ChakraProvider>,
);
