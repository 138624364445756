import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { strNomalize } from '../../helpers';

const DuplicateTable = ({ data }: any) => {
  return (
    <Table size='sm'>
      <Thead>
        <Tr>
          {data.data[0].map((k: string) => (
            <Th>{k}</Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {data.duplicate.map((d: any) => (
          <Tr>
            {data.data[0].map((k: string) => (
              <Td>{d[data.matchFormData[strNomalize(k)]]}</Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default DuplicateTable;
