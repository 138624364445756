import {
  ChevronDownIcon,
  ChevronUpIcon,
  CloseIcon,
  DeleteIcon,
  EditIcon,
} from '@chakra-ui/icons';
import {
  Icon as ChakraIcon,
  IconProps,
  Circle,
  SquareProps,
} from '@chakra-ui/react';
import { entries } from 'lodash';
import { ReactNode } from 'react';
import { IconType } from 'react-icons';
import { BiColumns } from 'react-icons/bi';
import * as hiIcons from 'react-icons/hi2';
import { IoMdLogOut } from 'react-icons/io';
import { colors, theme } from '../../theme';
import { useGlobalContext } from '../../useGlobalContext';

export const Icon = ({
  color,
  icon,
  ...props
}: IconProps & { icon?: ReactNode }) => (
  <ChakraIcon
    boxSize='30px'
    fontSize='25px'
    color={color || `${colors.main}.500`}
    transition='color 0.2s'
    _hover={
      props.onClick ? { cursor: 'pointer', color: `${colors.main}.300` } : {}
    }
    {...props}
  >
    {icon}
  </ChakraIcon>
);

export const CirlceIcon = ({
  icon,
  color,
  boxSize,
  ...props
}: SquareProps & { icon?: ReactNode; boxSize?: string }) => {
  const { darkLight } = useGlobalContext();
  return (
    <Circle
      boxSize={boxSize || theme.fontSize.headerBig}
      padding='5px'
      color={color || theme.normal[darkLight]}
      cursor={props.onClick ? 'pointer' : 'default'}
      mx={0.5}
      _hover={{
        ...props._hover,
      }}
      {...props}
      bgColor={props.backgroundColor || props.bgColor || theme.btnBg[darkLight]}
    >
      {icon}
    </Circle>
  );
};

export const icons = {
  logout: IoMdLogOut,
  edit: EditIcon,
  cross: CloseIcon,
  arrowUp: <ChevronUpIcon />,
  arrowDown: <ChevronDownIcon />,
  trash: <DeleteIcon />,
  page: BiColumns,
  column: hiIcons.HiViewColumns,
  duplicate: <hiIcons.HiOutlineDocumentDuplicate />,
};

export const allIcons: Record<string, IconType> = hiIcons;

export const pageIcons: Record<string, IconType> = entries(hiIcons).reduce(
  (prev, [key, value]) =>
    /Outline/.test(key) ? prev : { ...prev, [key]: value },
  {},
);
