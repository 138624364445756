import { ReactNode, useMemo } from 'react';
import Table from './tableComponents/Table';
import { keyBy } from 'lodash';
import { TColumnData, TTab, useTabsContext } from '../tabs/useTabsContext';
import { isEmpty, useGetSelectFromId } from '../../helpers';
import { addressInputs, addressInputskeys } from './actions/ManageInputs';
import useTableContainer from './TableContainer';
import { useGlobalContext } from '../../useGlobalContext';

export type TColumn = {
  header?: ReactNode;
  accessorKey: string;
  accessorFn?: (row: any, idx: number) => any;
  meta: {
    _id: string;
  };
};

const useFormatForTable = () => {
  const getSelectFromId = useGetSelectFromId();
  const { userList, canI } = useTabsContext();

  return (columns: TColumnData[] = []): TColumn[] =>
    columns.reduce((prev, column) => {
      let col: any = [
        {
          accessorKey: column.label,
          accessorFn: (row: any) => row[column.name_id || ''],
          meta: {
            _id: column.name_id || '',
            type: column.type,
            unity: column.unity,
          },
        },
      ];

      if (
        column.name_id &&
        column.type !== 'address' &&
        !canI(column.name_id, 'read')
      ) {
        return prev;
      }

      if (/select/.test(column.type)) {
        const select = getSelectFromId(column.name_id || '');
        const name_id = `${select?.name_id}`;

        col = [
          {
            accessorKey: column.label,
            accessorFn: (row: any) =>
              (!isEmpty(row[name_id]) &&
                (select?.type === 'multiselect'
                  ? row[name_id]
                      .map((s: any) => select?.options?.[Number(s)])
                      .join(', ')
                  : select?.options?.[Number(row[name_id])])) ||
              '-',
            meta: {
              _id: name_id,
              colors: select?.colors,
              colorKey: name_id,
              type: column.type,
              options: select?.options,
              isMulti: select?.type === 'multiselect',
            },
          },
        ];
      }

      if (column.type === 'users') {
        const usersById = keyBy(userList, '_id');
        const name_id = `${column?.name_id}`;
        const colors = userList.reduce(
          (prev, user) => ({
            ...prev,
            [user._id]: user.userColor,
          }),
          {},
        );

        col = [
          {
            accessorKey: column.label,
            accessorFn: (row: any) => {
              return (
                usersById[row[name_id]]?.name ||
                usersById[row[name_id]]?.login ||
                '-'
              );
            },
            meta: {
              _id: name_id,
              colors: colors,
              colorKey: name_id,
              type: column.type,
            },
          },
        ];
      }

      if (column.type === 'address') {
        col = addressInputskeys.reduce(
          (prevField, inputKey, idx) =>
            !(column as any)[inputKey] ||
            !canI(`${column.name_id}_${inputKey}`, 'read')
              ? prevField
              : [
                  ...prevField,
                  {
                    accessorKey: `${addressInputs[idx]} ${column.label}`,
                    accessorFn: (row: any) =>
                      row[`${column.name_id}_${inputKey}`],
                    meta: {
                      _id: `${addressInputs[idx]}_${column.label}`,
                      type: column.type,
                    },
                  },
                ],
          [] as any,
        );

        if (column.showValidation) {
          col.push({
            accessorKey: `Valide`,
            accessorFn: (row: any) =>
              row[`${column.name_id}`] ? 'oui' : 'non',
            meta: {
              _id: `vaidation_${column.label}`,
              type: column.type,
              showValidation: true,
            },
          });
        }
      }

      return [...prev, ...col];
    }, [] as any);
};

const DynamicTable = ({ tab }: { tab?: TTab }) => {
  const { data, currentTab, tabToUpdate } = useTabsContext();
  const { customMenuSelected, setModalType } = useGlobalContext();
  const formatForTable = useFormatForTable();

  const currTab = tab || currentTab || tabToUpdate;

  const columns = useMemo(
    () => formatForTable((currTab || {}).tableColumns),
    [tab, currentTab, tabToUpdate],
  );

  const props = useTableContainer({
    data,
    columns,
    onEditClick:
      customMenuSelected === 'duplicate'
        ? () => {
            setModalType('fixDuplicate');
          }
        : null,
  });
  return currTab?._id ? <Table {...props} /> : null;
};

export default DynamicTable;
