import Table from './tableComponents/Table';
import useTableContainer from './TableContainer';

export type TNormalTable = {
  data: any[];
  columns: any[];
  editEntryKey?: string;
  noResize?: boolean;
  onLoad?: () => void;
};

const NormalTable = (props: TNormalTable) => {
  const { columns } = props;
  const tableProps = useTableContainer(props);

  return columns?.length ? <Table {...tableProps} /> : null;
};

export default NormalTable;
