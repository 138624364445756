// src/theme.ts

import { extendTheme } from '@chakra-ui/react';

const exTheme = extendTheme({
  components: {
    Input: {
      baseStyle: {
        field: {
          height: '33px',
          lineHeight: '33px',
          _disabled: {
            color: '#999999',
            backgroundColor: '#f2f2f2 !important',
            opacity: 1,
          },
        },
      },
    },
    Select: {
      baseStyle: {
        field: {
          _disabled: {
            color: '#999999',
            backgroundColor: '#f2f2f2 !important',
            opacity: 1,
          },
          height: '33px',
          lineHeight: '33px',
        },
      },
    },
    Textarea: {
      baseStyle: {
        _disabled: {
          color: '#999999',
          backgroundColor: '#f2f2f2 !important',
          opacity: 1,
        },
      },
    },
    Button: {
      baseStyle: {
        // borderRadius: '16px',
        // boxShadow: '45px 76px 113px 7px rgba(112, 144, 176, 0.08)',
        // transition: '.25s all ease',
        // boxSizing: 'border-box',
        // _focus: {
        //   boxShadow: 'none',
        // },
        // _active: {
        //   boxShadow: 'none',
        // },
      },
    },
  },
  styles: {
    global: {
      '.container': {
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 25px 50px -12px',
      },
      // 'html, body': {
      //   'font-size': '13.2px !important',
      // },
      // 'table tr th, table tr td': {
      //   'font-size': '11.2px !important',
      // },
      // '.chakra-badge': {
      //   'font-size': '10.2px !important',
      // },
      // 'header, h2': { 'font-size': '20px !important' },
    },
  },
  fonts: {
    body: `'Roboto Condensed', sans-serif`,
    heading: `'Roboto Condensed', sans-serif`,
  },
});

export const colorList = [
  'red',
  'orange',
  'yellow',
  'green',
  'teal',
  'blue',
  'cyan',
  'purple',
  'pink',
  'gray',
];

export const colors = {
  main: colorList[5],
};

export const theme = {
  none: ['#ffffff', '#ffffff'],
  background: ['#f4f7fe', '#465691'],
  navbarBg: ['#ffffff', '#101c45'],
  main: ['#2d3748', '#ffffff'],
  mainBis: ['#ffffff', '#ffffff'],
  secondary: ['#8f9bba', '#a3aed0'],
  btnBg: ['#f5f7ff', '#1e2950'],
  lighter: ['blue.50', '#1e2950'],
  light: ['blue.200', '#1e2950'],
  normal: ['blue.400', '#1e2950'],
  dark: ['blue.600', '#1e2950'],
  darker: ['blue.800', '#1e2950'],
  fontSize: {
    XXL: '40px',
    headerBig: '34px',
    header: '24px',
    bigger: '20px',
    big: '18px',
    normal: '16px',
    small: '14px',
    table: '13px',
    xSmall: '11px',
    xxSmall: '8px',
  },
  margin: ['10px', '16px'],
};

export default exTheme;
