import { useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { List, ListItem } from '@chakra-ui/react';

type TItemTypes = 'ITEM' | 'SUBTAB' | 'PAGE' | 'SUBMENU' | 'LIST';

type TDraggableItem = {
  item: any;
  index: number;
  onDrop?: any;
  moveItem: (fromIndex: number, toIndex: number) => void;
  isDraggable?: boolean;
  typeOfItem: TItemTypes;
};

const DraggableItem = ({
  item,
  index,
  onDrop,
  moveItem,
  isDraggable,
  typeOfItem,
}: TDraggableItem) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: typeOfItem,
    hover: (draggedItem: any) => {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
    drop: onDrop,
  });

  const [{ isDragging }, drag] = useDrag({
    type: typeOfItem,
    item: { type: typeOfItem, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  if (isDraggable) {
    drag(drop(ref));
  }

  return (
    <ListItem
      ref={ref}
      borderWidth={1}
      borderRadius='md'
      boxShadow='sm'
      opacity={isDragging ? 0.5 : 1}
      cursor={isDraggable ? 'move' : 'pointer'}
      m={3}
      mt={0}
    >
      {item.content}
    </ListItem>
  );
};

type TDragAndDropList = {
  setData: (items: any[]) => void;
  itemsList: any[];
  finalItems: any[];
  nonDraggableIndexes?: number[];
  typeOfItem: TItemTypes;
};

const DragAndDropList = ({
  finalItems,
  itemsList,
  setData,
  nonDraggableIndexes,
  typeOfItem,
}: TDragAndDropList) => {
  const [items, setItems] = useState(
    itemsList.map((item, idx) => ({ id: idx, content: item })),
  );

  useEffect(() => {
    setItems(itemsList.map((item, idx) => ({ id: idx, content: item })));
  }, [itemsList]);

  const moveItem = (fromIndex: number, toIndex: number) => {
    setItems((prevItems) => {
      const updatedItems = [...prevItems];
      const [movedItem] = updatedItems.splice(fromIndex, 1);
      updatedItems.splice(toIndex, 0, movedItem);
      return updatedItems;
    });
  };

  const onDrop: any = () => {
    setData(items.map((i) => finalItems[i.id]));
  };

  return (
    <List flexDirection='column'>
      {items.map((item, index) => (
        <DraggableItem
          isDraggable={!nonDraggableIndexes?.includes(index)}
          key={item.id}
          item={item}
          index={index}
          moveItem={moveItem}
          onDrop={onDrop}
          typeOfItem={typeOfItem}
        />
      ))}
    </List>
  );
};

export default DragAndDropList;
