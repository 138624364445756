import { useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Column, Table as TanstackTable } from '@tanstack/react-table';
import DebouncedInput from './DebounceInput';

const Filter = ({
  column,
  table,
}: {
  column: Column<any, unknown>;
  table: TanstackTable<any>;
}) => {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  const sortedUniqueValues = useMemo(
    () =>
      typeof firstValue === 'number'
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()],
  );

  const meta: any = column.columnDef.meta;

  return (
    <Box w='100%'>
      {typeof firstValue === 'number' ? (
        <Flex>
          <DebouncedInput
            type='number'
            min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
            max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
            value={(columnFilterValue as [number, number])?.[0] ?? ''}
            onChange={(value) =>
              column.setFilterValue((old: [number, number]) => [
                value,
                old?.[1],
              ])
            }
            placeholder='Min'
            mr={1}
            p={1}
          />
          <DebouncedInput
            type='number'
            min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
            max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
            value={(columnFilterValue as [number, number])?.[1] ?? ''}
            onChange={(value) =>
              column.setFilterValue((old: [number, number]) => [
                old?.[0],
                value,
              ])
            }
            placeholder='Max'
            p={1}
          />
        </Flex>
      ) : (
        <>
          {(/select|user/.test(meta?.type) || meta?.showValidation) && (
            <datalist id={column.id + 'list'}>
              {sortedUniqueValues.slice(0, 5000).map((value: any) => (
                <option value={value} key={value} />
              ))}
            </datalist>
          )}
          <DebouncedInput
            type='text'
            value={(columnFilterValue ?? '') as string}
            onChange={(value) => column.setFilterValue(value)}
            placeholder={`Search...`}
            list={column.id + 'list'}
            p={1}
          />
        </>
      )}
    </Box>
  );
};

export default Filter;
