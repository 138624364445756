import { useCallback, useEffect, useRef } from 'react';
import {
  Text,
  Box,
  Flex,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Input,
  Button,
} from '@chakra-ui/react';
import { TColumnData, TTab, useTabsContext } from './useTabsContext';
import { groupBy, keyBy, keys, orderBy } from 'lodash';
import { CirlceIcon, allIcons } from '../utils/Icon';
import DragAndDropList from '../dragAndDrop/DragAndDropList';
import { colors } from '../../theme';

const Element = ({
  col,
  setTableColumns,
  setHasChanges,
}: {
  col: TColumnData;
  setTableColumns: React.Dispatch<React.SetStateAction<TColumnData[]>>;
  setHasChanges: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const handleChange = useCallback(() => {
    setHasChanges(true);
    setTableColumns((c) => c.filter((f) => f.name_id !== col.name_id));
  }, [col]);

  return (
    <Flex
      borderRadius={5}
      p={1}
      alignItems='center'
      fontWeight={col.type === 'titre' ? 'bold' : ''}
    >
      <Text
        textTransform='uppercase'
        mr={2}
        fontWeight={col.type === 'title' ? 'bold' : ''}
      >
        {col.label}
      </Text>
      {col.type !== 'title' && `(ID: ${col.name_id}, TYPE: ${col.type})`}
      <Box flexGrow={1} />
      <CirlceIcon ml={3} as={allIcons.HiMiniXMark} onClick={handleChange} />
    </Flex>
  );
};

type TEditColumns = {
  tab: TTab;
  tableColumns: TColumnData[];
  setTableColumns: React.Dispatch<React.SetStateAction<TColumnData[]>>;
  setHasChanges: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditColumns = ({
  tab,
  tableColumns,
  setTableColumns,
  setHasChanges,
}: TEditColumns) => {
  const { dataInputs } = useTabsContext();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setTableColumns(tab?.tableColumns || []);
  }, [tab]);

  const activeInput = keyBy(tableColumns, 'name_id');
  const inputs = dataInputs?.filter(
    (col) => !activeInput[`${col.name_id}`] && col.name_id !== 'planning_type',
  );
  const inputsByGroup = groupBy(inputs, ({ group }) => (!group ? '' : group));
  const orderedKeys = orderBy(keys(inputsByGroup));

  return (
    <Flex>
      <Box
        flexBasis='50%'
        borderRight='2px solid'
        borderColor='gray.300'
        pr={7}
      >
        <Text fontSize={26} fontWeight={500}>
          Liste des champs
        </Text>
        <Text mb={3}>(Click pour les ajouter)</Text>
        <Accordion allowMultiple>
          {orderedKeys.map((groupName) => (
            <AccordionItem>
              <AccordionButton textTransform='capitalize'>
                {groupName || 'Champs de base'}
              </AccordionButton>
              <AccordionPanel>
                {inputsByGroup[groupName].map((col) => (
                  <Flex
                    key={col._id}
                    borderRadius={5}
                    w='fit-content'
                    onClick={() => {
                      setHasChanges(true);
                      setTableColumns((c) => [...c, col]);
                    }}
                    cursor='pointer'
                    p={1}
                    _hover={{ backgroundColor: `${colors.main}.100` }}
                  >
                    <Text textTransform='uppercase' mr={2}>
                      {col.label}
                    </Text>
                    ({col.name_id})
                  </Flex>
                ))}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Box>
      <Box flexGrow={1} ml={7}>
        <Text mb={3} fontSize={26} fontWeight={500}>
          Champs ajoutés
        </Text>
        <Flex mx={3} mb={3}>
          <Input ref={inputRef} mr={3} placeholder='Ajouter un titre' />
          <Button
            onClick={() => {
              setTableColumns((tc) => [
                {
                  label: inputRef.current?.value || '',
                  type: 'title',
                  name_id: `title_${inputRef.current?.value || ''}`,
                },
                ...tc,
              ]);
            }}
          >
            +
          </Button>
        </Flex>
        <DragAndDropList
          setData={(d) => {
            setTableColumns(d);
            setHasChanges(true);
          }}
          itemsList={tableColumns.map((item) => (
            <Element
              col={item}
              setTableColumns={setTableColumns}
              setHasChanges={setHasChanges}
            />
          ))}
          finalItems={tableColumns}
          typeOfItem='ITEM'
        />
      </Box>
    </Flex>
  );
};

export default EditColumns;
