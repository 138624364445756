import { useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { DefaultEventsMap } from 'socket.io';
import { Box, Flex } from '@chakra-ui/react';
import { useInstanceError } from './api/init';
import Login from './components/login/Login';
import { GlobalProvider, useGlobalContext } from './useGlobalContext';
import Navbar from './components/layout/Navbar';
import { TabsProvider } from './components/tabs/useTabsContext';
import Main from './components/layout/Main';
import { theme } from './theme';

type TResponse = { success: boolean; token: string; data: any };

const findElement = (el: Element, selector: string) => {
  if (el.parentElement?.querySelector(selector)) {
    (el.parentElement?.querySelector(selector) as HTMLButtonElement)?.click();
  } else if (el.parentElement) {
    findElement(el.parentElement, selector);
  }
};

const App = () => {
  useInstanceError();

  const { user, token, darkLight, setToken, setLogin, setCustomMenuSelected } =
    useGlobalContext();

  const [socketInstance, setSocketInstance] =
    useState<Socket<DefaultEventsMap, DefaultEventsMap>>();

  const handleLogin = (resp: TResponse) => {
    if (!resp?.success) return;
    setToken(resp.token);
    setLogin('');
    setCustomMenuSelected('');
  };

  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Enter' && document.activeElement) {
        findElement(document.activeElement, '.submit');
      }
    });

    const socketI = io(process.env.REACT_APP_BASE_URL, {
      transports: ['websocket'],
    });

    socketI.on('ping', () => {
      setSocketInstance(socketI);
    });
  }, []);

  return (
    <TabsProvider>
      {!token || user.tempPasswordForced || user.tempPassword ? (
        <Flex
          backgroundImage='/bg.jpeg'
          backgroundSize='cover'
          bgRepeat='no-repeat'
          alignItems='center'
          bgPosition='center'
        >
          <Login handleLogin={handleLogin} />
        </Flex>
      ) : (
        <Flex
          bgColor={theme.background[darkLight]}
          w='100vw'
          overflow='hidden'
          maxH='100%'
        >
          <Box
            position='fixed'
            bgColor={theme.light[darkLight]}
            height='400px'
            width='100%'
            top={0}
            left={0}
          />
          <Navbar />
          <Main />
        </Flex>
      )}
      {/* <Button
        onClick={() => {
          socketInstance?.emit('click', {
            token: user._id,
            selector: '.ifr-call-button',
          });
        }}
      >
        Décrocher
      </Button>
      <Button
        onClick={() => {
          socketInstance?.emit('click', {
            token: user._id,
            selector: '.cc-button-control_hangup',
          });
        }}
      >
        Racrocher
      </Button> */}
      {/* <iframe
        width='600'
        height='600'
        loading='lazy'
        allow='microphone; speaker-selection; autoplay; camera; display-capture; hid'
        sandbox='allow-popups allow-scripts allow-same-origin'
        src={`${
          process.env.REACT_APP_BASE_URL
        }/api/proxy?bearer=${encodeURIComponent(
          token,
        )}&company=${localStorage.getItem('company')}`}
        // src='/api/proxy'
      ></iframe> */}
    </TabsProvider>
  );
};

export default (props: any) => (
  <GlobalProvider>
    <App {...props} />
  </GlobalProvider>
);
