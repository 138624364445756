import { useEffect, useState } from 'react';
import { Text, Flex, useToast, Box } from '@chakra-ui/react';
import useForm, { TField } from '../../form/Form';
import { useGlobalContext } from '../../../useGlobalContext';
import { useTabsContext } from '../../tabs/useTabsContext';
import Calendar from '../../calendar/Calendar';
import { Moment } from 'moment';
import { addressInputskeys } from './ManageInputs';
import {
  addAppointment,
  deleteAppointment,
  updateAppointment,
} from '../../../api/planningApi';
import { theme } from '../../../theme';

const useManagePlanning = () => {
  const toast = useToast();
  const { planningTab, rowData, userList, getUsersList } = useTabsContext();
  const { fetchAppointments, onClose, planningAddress } = useGlobalContext();

  const [selectedDate, setSelectedDate] = useState<Moment>();

  useEffect(() => {
    getUsersList();
  }, []);

  const filteredUsers = userList.filter((user) => user.isPlanable);

  const usersOptions = filteredUsers.map((user) => ({
    label: user.name,
    value: user._id,
  }));

  const usersColors = filteredUsers.map((user) => user.userColor);

  const inputs: TField[] = [
    {
      name: planningAddress || 'planningAddress',
      label: 'RDV',
      type: 'address',
    },
    {
      name: 'assignedUser',
      label: 'Assigné à',
      type: 'select',
      options: usersOptions,
      colors: usersColors,
    },
    {
      name: 'planningType',
      label: 'Type de rdv',
      type: 'select',
      options: planningTab?.planningType?.options?.map(
        (option: string, idx: number) => ({ label: option, value: idx }),
      ),
    },
    // ...(planningTab?.tableColumns || []),
  ];

  const { Form, formData } = useForm(inputs);

  const handlePlanning = async () => {
    const data: Record<string, any> = {
      rowId: rowData._id,
      planningDate: selectedDate?.format('YYYY-MM-DDTHH:mm:00'),
      assignedUser: formData.assignedUser,
      planningType: formData.planningType,
      planningAddress: formData[`${planningAddress}`],
      planningAddressDescription:
        formData[`${planningAddress}_addressDescription`],
      ...addressInputskeys.reduce(
        (prev, key) => ({
          ...prev,
          [`planningAddress_${key}`]: formData[`${planningAddress}_${key}`],
        }),
        {},
      ),
    };

    if (!data.planningDate || data.planningType == null) {
      // if (!data.planningDate || !data.assignedUser || data.planningType == null) {
      toast({
        status: 'error',
        title: 'Vous devez choisir un créneau et un type',
        // "Vous devez choisir un créneau, un type et assigner le rdv à quelqu'un",
      });
    } else {
      setSelectedDate(undefined);
      await addAppointment(data);
      await fetchAppointments();
      onClose();
    }
  };

  const handleEditPlanning = async () => {
    const data: Record<string, any> = {
      planningDate: selectedDate?.format('YYYY-MM-DDTHH:mm:00'),
      assignedUser: formData.assignedUser,
      planningType: formData.planningType,
      planningAddress: formData.planningAddress,
      ...addressInputskeys.reduce(
        (prev, key) => ({
          ...prev,
          [`planningAddress_${key}`]: formData[`planningAddress_${key}`],
        }),
        {},
      ),
    };

    // if (!data.planningDate || !data.assignedUser || data.planningType == null) {
    if (!data.planningDate || data.planningType == null) {
      toast({
        status: 'error',
        title: 'Vous devez choisir un créneau et un type',
      });
    } else {
      setSelectedDate(undefined);
      await updateAppointment(rowData._id, data);
      await fetchAppointments();
      onClose();
    }
  };

  const renderManagePlanning = (
    <>
      <Flex
        alignItems='flex-start'
        mb={theme.margin}
        flexWrap='wrap'
        position='relative'
        zIndex={3}
      >
        {Form.map((f, idx) => (
          <Box w={!idx ? '100%' : ['100%', '50%']} pl={[0, idx === 2 ? 3 : 0]}>
            {f}
          </Box>
        ))}
      </Flex>
      {/* switch entre calendar et MAP */}
      {/* <Switch /> */}
      <Calendar
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        datas={formData}
      />
    </>
  );

  const renderDeleteAppoint = <Text>Supprimer ce rdv ?</Text>;

  const handleDeletePlanning = async () => {
    await deleteAppointment(rowData._id);
    await fetchAppointments();
    onClose();
  };

  return {
    addToPlanning: {
      component: renderManagePlanning,
      action: handlePlanning,
      title: 'Planifier un RDV',
      actionLabel: 'Valider',
    },
    updatePlanning: {
      component: renderManagePlanning,
      action: handleEditPlanning,
      title: 'Modifier un RDV',
      actionLabel: 'Sauvegarder',
    },
    deletePlanning: {
      component: renderDeleteAppoint,
      action: handleDeletePlanning,
      title: 'Supprimer un RDV',
      actionLabel: 'Confirmer',
    },
  };
};

export default useManagePlanning;
