import { Text } from '@chakra-ui/react';
import {
  deleteDocumentList,
  postDocumentList,
  updateDocumentList,
} from '../../../api/tabsApi';
import useForm from '../../form/Form';
import { useGlobalContext } from '../../../useGlobalContext';
import { useTabsContext } from '../../tabs/useTabsContext';

const useManageDocuments = () => {
  const { rowData, getDocumentList } = useTabsContext();
  const { isAdmin, onClose } = useGlobalContext();

  const inputs = [
    {
      label: 'Nom',
      name: 'name',
      type: 'text',
    },
    {
      label: 'Description',
      name: 'description',
      type: '',
    },
  ];

  const { Form, formData, pending } = useForm(inputs);

  const handleAdd = async () => {
    await postDocumentList(formData);
    await getDocumentList(true);
    onClose();
  };

  const handleUpdate = async () => {
    await updateDocumentList(formData._id, formData);
    await getDocumentList(true);
    onClose();
  };

  const handleDelete = async () => {
    await deleteDocumentList(rowData._id);
    await getDocumentList(true);
    onClose();
  };

  const deletePermission = isAdmin;

  const renderDeleteEntry = (
    <Text>Confirm you want to delete this entry ?</Text>
  );

  return {
    addDocument: {
      component: Form,
      action: handleAdd,
      title: 'Ajouter document à la liste',
      actionLabel: 'Ajouter',
    },
    editDocument: {
      component: Form,
      canDelete: deletePermission ? 'deleteDocument' : '',
      action: handleUpdate,
      pending,
      title: 'Editer document',
      actionLabel: 'Sauvegarder',
    },
    deleteDocument: {
      component: renderDeleteEntry,
      action: handleDelete,
      title: 'Supprimer un document de la liste',
      actionLabel: 'Confirmer',
    },
  };
};

export default useManageDocuments;
