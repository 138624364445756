import { useCallback, useEffect, useRef } from 'react';
import { useDrop } from 'react-dnd';
import DraggableItem from './DraggableItem';
import { Flex, Tag } from '@chakra-ui/react';
import { colors } from '../../theme';

type TDragDropElementProps = {
  id: string;
  noDrag?: boolean;
  isHover?: string;
  setIsHover?: (id?: string) => void;
  isMinutes?: boolean;
  onDrop?: any;
  onDrag?: any;
  children?: any;
};

const DragDropElement = ({
  id,
  noDrag,
  isHover,
  setIsHover,
  isMinutes,
  onDrop,
  onDrag,
  children,
}: TDragDropElementProps) => {
  const preventRender = useRef<any>(null);

  const onHover = useCallback(() => {
    if (id !== preventRender.current) {
      preventRender.current = id;
      setIsHover?.(id);
    }
  }, [id, preventRender.current]);

  const [, drop] = useDrop({
    accept: 'CALENDAR',
    hover: onHover,
    drop: (item: any) => {
      onDrop?.(item.id, id);
      preventRender.current = null;
      setIsHover?.();
    },
  });

  useEffect(() => {
    if (id !== isHover) {
      setTimeout(() => {
        preventRender.current = null;
      }, 10);
    }
  }, [isHover]);

  return isMinutes ? (
    <>
      {['00', '15', '30', '45'].map((min) => (
        <DragDropElement
          noDrag
          id={`${id}:${min}`}
          isHover={isHover}
          setIsHover={setIsHover}
          onDrop={onDrop}
        >
          <Tag
            flexGrow={1}
            mx={1}
            opacity={isHover?.split(':')[0] === id ? 1 : 0}
            backgroundColor={
              isHover === `${id}:${min}`
                ? `${colors.main}.300`
                : `${colors.main}.100`
            }
          >
            {min}
          </Tag>
        </DragDropElement>
      ))}
    </>
  ) : (
    <Flex ref={drop} style={{ width: '100%' }}>
      {!isMinutes &&
        (noDrag ? (
          children
        ) : (
          <DraggableItem id={id} onDrag={onDrag} onDrop={onDrop}>
            {children}
          </DraggableItem>
        ))}
    </Flex>
  );
};

export default DragDropElement;
