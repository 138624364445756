import { useEffect, useState } from 'react';
import {
  Text,
  Button,
  Box,
  Flex,
  Icon,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';

import { colors, theme } from '../../theme';
import { TColumnData, TTab, useTabsContext } from './useTabsContext';
import { useGlobalContext } from '../../useGlobalContext';
import { CirlceIcon, allIcons, icons } from '../utils/Icon';
import EditColumns from './EditColumns';
import { getHeight } from '../../helpers';
import { updateColumn, updateTabOrder } from '../../api/tabsApi';
import DragAndDropList from '../dragAndDrop/DragAndDropList';

const Element = ({
  tab,
  idx,
  onClick,
  selected,
}: {
  tab: TTab;
  idx: number;
  onClick: any;
  selected: boolean;
}) => {
  const { setModalType, isEditMode } = useGlobalContext();
  const { subTabs, setTabToUpdate } = useTabsContext();

  return (
    <Flex
      p={2}
      key={tab?._id}
      textTransform='capitalize'
      onClick={() => onClick(idx)}
      borderWidth='1px'
      borderRadius={5}
      backgroundColor={selected ? `${colors.main}.300` : 'white'}
      borderColor={selected ? `${colors.main}.600` : 'white'}
      color={selected ? 'white' : 'black'}
      fontWeight={selected ? 'bold' : 'auto'}
    >
      {tab?.icon && <Icon as={allIcons[tab?.icon]} boxSize={25} mr={2} />}
      <Text whiteSpace='nowrap'>
        {idx === 0 ? 'vue principale' : tab?.title}
      </Text>
      <Box flexGrow={1} />
      {idx !== 0 && isEditMode && (
        <>
          <CirlceIcon
            as={icons.edit}
            ml={3}
            onClick={() => {
              setTabToUpdate(subTabs[idx]);
              setModalType('editSubTab');
            }}
          />
          <CirlceIcon
            as={icons.cross}
            onClick={() => {
              setModalType('delTab');
            }}
          />
        </>
      )}
    </Flex>
  );
};

const SubTabsWithOrder = () => {
  const {
    subTabs,
    currentTab,
    tabToUpdate,
    tabTitles,
    setTabToUpdate,
    getTabs,
  } = useTabsContext();
  const { darkLight, setModalType, customMenuSelected } = useGlobalContext();

  const [tabHeight, setTabHeight] = useState(0);
  const [orderedSubTabs, setOrderedSubTabs] = useState(subTabs);
  const [hasChanges, setHasChanges] = useState(false);

  const isPlanning = /planning/.test(customMenuSelected);
  const isDuplicate = /duplicate/.test(customMenuSelected);

  const [tableColumns, setTableColumns] = useState<TColumnData[]>(
    tabToUpdate?.tableColumns || [],
  );

  const update = async () => {
    if (!tabToUpdate && !isPlanning && !isDuplicate) return;

    setHasChanges(false);

    await updateColumn({
      id: tabToUpdate?._id,
      data: tableColumns?.map((t) => t.name_id),
      isPlanning,
      isDuplicate,
    });

    await getTabs();
  };

  useEffect(() => {
    setTabToUpdate(orderedSubTabs[0]);
    setTabToUpdate(currentTab);
  }, [currentTab]);

  useEffect(() => {
    setHasChanges(false);
  }, [tabToUpdate]);

  useEffect(() => {
    setOrderedSubTabs(subTabs);
  }, [subTabs]);

  useEffect(() => {
    const ids = orderedSubTabs.map((t) => t._id);

    if (ids.join('') !== subTabs.map((t) => t._id).join('')) {
      ids.shift();
      updateTabOrder(ids);
      getTabs();
    }
  }, [orderedSubTabs]);

  useEffect(() => {
    const height = getHeight(
      '#subTabsPanels',
      'top',
      '#mainContainer',
      'paddingBottom',
    );

    setTabHeight(height);
  }, [window.innerWidth, window.innerHeight]);

  if (currentTab?.cloneId) {
    return (
      <>
        <Flex
          alignItems='center'
          bgColor={`${colors.main}.50`}
          w='fit-content'
          p={2}
          borderRadius={5}
        >
          Cette page est un clone de la page
          <Text
            textTransform='capitalize'
            fontSize='1.1em'
            fontWeight='500'
            ml={1}
          >
            {tabTitles[currentTab?.cloneId] || ''}
          </Text>
          , aucune modification est possible excepté les filtres
        </Flex>
        {!isPlanning && (
          <Button
            mt={2}
            onClick={() => {
              setModalType('editFilters');
            }}
            backgroundColor={`${colors.main}.300`}
            color='white'
          >
            Editer les filtres
          </Button>
        )}
      </>
    );
  }

  return !tabToUpdate ? null : (
    <Box textAlign='center' position='relative'>
      <Flex alignItems='center' pb={2}>
        <Box flexGrow={1} />
        <Button
          onClick={() => {
            setModalType('addSubTab');
          }}
          ml={3}
          backgroundColor={`${colors.main}.300`}
          color='white'
        >
          Ajouter un onglet
        </Button>
        {!isPlanning && (
          <Button
            ml={2}
            onClick={() => {
              setModalType('editFilters');
            }}
            backgroundColor={`${colors.main}.300`}
            color='white'
          >
            Editer les filtres
          </Button>
        )}
        <CirlceIcon
          as={allIcons.HiPlusSmall}
          onClick={() => setModalType('addInput')}
          mx={3}
        />
        <Button
          onClick={update}
          backgroundColor={`${colors.main}.500`}
          color='white'
        >
          Sauvegarder
        </Button>
        {hasChanges && (
          <Box flexGrow={0} ml={3}>
            <Alert status='warning' p={2} borderRadius={10}>
              <AlertIcon />
              Pensez à sauvegarder
            </Alert>
          </Box>
        )}
      </Flex>
      <Flex id='subTabsPanels' maxH={`calc(100vh - ${tabHeight}px)`} ml={-3}>
        {tabToUpdate && (
          <DragAndDropList
            nonDraggableIndexes={[0]}
            setData={setOrderedSubTabs}
            itemsList={orderedSubTabs.map((item, idx) => (
              <Element
                tab={item}
                idx={idx}
                onClick={() => setTabToUpdate(item)}
                selected={tabToUpdate._id === item._id}
              />
            ))}
            finalItems={orderedSubTabs}
            typeOfItem='SUBTAB'
          />
        )}
        <Box
          flexGrow={1}
          borderWidth='2px'
          overflow='auto'
          p={7}
          ml={3}
          bgColor={theme.navbarBg[darkLight]}
        >
          {tabToUpdate && (
            <EditColumns
              tab={tabToUpdate as TTab}
              tableColumns={tableColumns}
              setTableColumns={setTableColumns}
              setHasChanges={setHasChanges}
            />
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default SubTabsWithOrder;
