import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { concat } from 'lodash';
import {
  fetchData,
  fetchDataCount,
  fetchDocumentList,
  fetchInputs,
  fetchRowData,
  fetchTabs,
  getRoles,
  getUsers,
} from '../../api/tabsApi';
import { useGlobalContext } from '../../useGlobalContext';
import { indexOfObject } from '../../helpers';

export type TColumnData = {
  label: string;
  name_id?: string;
  type: string;
  unity?: string;
  _id?: string;
  selectName?: string;
  options?: any[];
  addressFields?: Record<string, boolean>;
  showValidation?: boolean;
};

export type TTab = {
  _id: string;
  cloneId?: string;
  parent_id?: string;
  title: string;
  tableColumns: TColumnData[];
  filters: Record<string, any>;
  icon?: string;
  isMenu?: boolean;
  childs?: TTab[];
  menuChilds?: TTab[];
  planable?: boolean;
  planningType?: any;
  isPlanning?: boolean;
};

export type TTabToUpdate = Partial<TTab>;

export type TSettingsTab = {
  _id: string;
  title: string;
  icon?: string;
} & Partial<TTab>;

export type TInput = {
  _id: string;
  label: string;
  name_id?: string;
  type: string;
  options?: string[];
  colors?: string[];
  group?: string;
  fromCRM?: boolean;
};

export type THistory = {
  user: string;
  rowId: string;
  diff: any[];
  date: string;
  isCreate: boolean;
  isPlanning: boolean;
};

const useTabs = () => {
  const {
    isOpen,
    token,
    isSuper,
    isAdmin,
    userRole,
    customMenuSelected,
    setCustomMenuSelected,
  } = useGlobalContext();

  const [tabs, setTabs] = useState<TTab[]>([]);
  const [tabTitles, setTabTitles] = useState<Record<string, string>>({});
  const [settingsTabs, setSettingsTabs] = useState<TSettingsTab[]>([]);
  const [planningTab, setPlanningTab] = useState<TTab>();
  const [duplicateTab, setDuplicateTab] = useState<TTab>();
  const [userList, setUserList] = useState<any[]>();
  const [roleList, setRoleList] = useState<any[]>();
  const [dataInputs, setDataInputs] = useState<TInput[]>();
  const [documentList, setDocumentList] = useState<any[]>();
  const [data, setData] = useState<any[]>([]);
  const [noFilter, setNoFilter] = useState<boolean>();
  const [dataCount, setDataCount] = useState<Record<string, number>>({});

  const [currentTab, setCurrentTab] = useState<TTab | undefined>();
  const [tabToUpdate, setTabToUpdate] = useState<TTabToUpdate | undefined>();
  const [currentColumnId, setCurrentColumnId] = useState('');
  const [rowData, setRowData] = useState<Record<string, any>>({});
  const [appointsHistory, setAppointsHistory] = useState<Record<string, any>>(
    {},
  );
  const [rowHistory, setRowHistory] = useState<Record<string, any>>({});
  const [rowSelected, setRowSelected] = useState<Record<string, boolean>>({});
  const [globalFilter, setGlobalFilter] = useState<string | undefined>();

  const getTabs = async () => {
    const resp = await fetchTabs();
    setTabs(resp?.data || []);
    setTabTitles(resp?.tabsById || []);
    setSettingsTabs(resp?.customTabs || []);
    setPlanningTab(resp?.planningTab || []);
    setDuplicateTab(resp?.duplicateTab || []);
  };

  const getInputs = async (forced?: boolean) => {
    if (!dataInputs?.length || forced) {
      const resp = await fetchInputs();
      setDataInputs(resp?.data || []);
    }
  };

  const getDocumentList = async (forced?: boolean) => {
    if (!documentList?.length || forced) {
      const resp = await fetchDocumentList();
      setDocumentList(resp?.data || []);
    }
  };

  const getDataCount = async () => {
    const respCount = await fetchDataCount();
    setDataCount(respCount.data);
  };

  const getData = async (rowId?: string) => {
    const pageId = currentTab?._id || duplicateTab?._id;

    if (!pageId) return;
    if (!currentTab?._id && !/duplicate/.test(customMenuSelected)) return;

    setRowSelected({});

    const resp = rowId
      ? await fetchRowData(pageId, rowId)
      : await fetchData(pageId);

    setNoFilter(!!resp.noFilter);

    if (!rowId) {
      setData(resp.data || []);
      setAppointsHistory(resp?.appoints);
      setRowHistory(resp?.history);
    } else {
      const indexData = indexOfObject(data, '_id', rowId);

      if (resp.data[0]) {
        setData((d) => {
          const newD = [...d];
          if (indexData < 0) {
            newD.unshift(resp.data[0]);
          } else {
            newD[indexData] = resp.data[0];
          }
          return newD;
        });
        setRowData(resp.data[0]);
        setAppointsHistory((d) => ({ ...d, [rowId]: resp?.appoints[rowId] }));
        setRowHistory((d) => ({ ...d, [rowId]: resp?.history[rowId] }));
      } else {
        setData((d) => {
          const newD = [...d];
          if (indexData >= 0) {
            newD.splice(indexData, 1);
          }
          return newD;
        });
      }
    }

    getDataCount();
  };

  const getRolesList = async (forced?: boolean) => {
    if (!roleList?.length || forced) {
      const resp = await getRoles();
      setRoleList(resp.data || []);
    }
  };

  const getUsersList = async (forced?: boolean) => {
    if (!userList?.length || forced) {
      const resp = await getUsers();
      setUserList(resp.data || []);
    }
  };

  const toggleSelected = (id: string) => {
    setRowSelected((s) => ({
      ...s,
      [id]: !s[id],
    }));
  };

  const canI = (key: string, action: string) =>
    isSuper || isAdmin || userRole[key]?.includes(action);

  const formatedUserList =
    userList?.map((user) => ({
      ...user,
      role: roleList?.find((role) => role._id === user.role_id)?.name,
    })) || [];

  useEffect(() => {
    const update = async () => {
      if (token) {
        await getUsersList(true);
        await getInputs(true);
        await getDocumentList(true);
        await getTabs();
      }
    };

    update();
  }, [token]);

  useEffect(() => {
    getData();

    currentTab?._id && localStorage.setItem('currentTab', currentTab?._id);

    let curr = currentTab;

    currentTab?.childs?.forEach((child) => {
      if (child._id === tabToUpdate?._id) {
        curr = child;
      }
    });

    currentTab?.menuChilds?.forEach((mc) => {
      if (mc._id === tabToUpdate?._id) {
        curr = mc;
      }
    });

    if (currentTab?._id === tabToUpdate?._id) {
      curr = currentTab;
    }

    currentTab && setTabToUpdate(curr);
  }, [currentTab]);

  useEffect(() => {
    duplicateTab && /duplicate/.test(customMenuSelected) && getData();
    setGlobalFilter('');
  }, [customMenuSelected, duplicateTab]);

  useEffect(() => {
    const localCurrent = localStorage.getItem('currentTab');

    let curr;

    tabs.forEach((tab) => {
      if (
        tab._id === currentTab?._id ||
        (!currentTab && tab._id === localCurrent)
      ) {
        curr = tab;
      }

      tab.menuChilds?.forEach((mc) => {
        if (
          mc._id === currentTab?._id ||
          (!currentTab && mc._id === localCurrent)
        ) {
          curr = mc;
        }
      });
    });

    if (localCurrent && /settings|planning|duplicate/.test(localCurrent)) {
      setCustomMenuSelected(localCurrent);
    } else {
      setCurrentTab(curr || tabs[0]);
    }
  }, [tabs]);

  useEffect(() => {
    if (!isOpen) {
      setRowData({});
    }
  }, [isOpen]);

  const subTabs = useMemo(
    () =>
      (customMenuSelected === 'planning' && planningTab
        ? concat(planningTab || [], planningTab?.childs || [])
        : customMenuSelected === 'duplicate' && duplicateTab
        ? concat(duplicateTab || [], duplicateTab?.childs || [])
        : concat(currentTab || [], currentTab?.childs || [])) as TTab[],
    [customMenuSelected, currentTab, planningTab],
  );

  const value = useMemo(
    () => ({
      tabs,
      subTabs,
      tabTitles,
      dataInputs,
      data,
      dataCount,
      currentTab,
      roleList,
      currentColumnId,
      rowData,
      userList: formatedUserList,
      tabToUpdate:
        !tabToUpdate && customMenuSelected === 'planning'
          ? planningTab
          : !tabToUpdate && customMenuSelected === 'duplicate'
          ? duplicateTab
          : tabToUpdate,
      settingsTabs,
      ready:
        (!!tabs.length || !!settingsTabs.length || !!planningTab) &&
        userList &&
        dataInputs,
      rowSelected,
      globalFilter,
      planningTab,
      duplicateTab,
      appointsHistory,
      rowHistory,
      documentList,
      noFilter,
      setGlobalFilter,
      setRowSelected,
      toggleSelected,
      canI,
      setTabToUpdate,
      getRolesList,
      getUsersList,
      getTabs,
      getInputs,
      setCurrentTab,
      setData,
      getData,
      getDocumentList,
      setCurrentColumnId,
      setRowData,
      setTabs,
    }),
    [
      currentTab,
      tabToUpdate,
      tabs,
      rowData,
      currentColumnId,
      formatedUserList,
      dataInputs,
      rowSelected,
      globalFilter,
      planningTab,
      appointsHistory,
      rowHistory,
      documentList,
      noFilter,
    ],
  );

  return value;
};

const TabsContext = createContext({} as ReturnType<typeof useTabs>);

export const useTabsContext = () => useContext(TabsContext);

export const TabsProvider = ({ children }: { children: ReactNode }) => {
  const value = useTabs();

  return <TabsContext.Provider value={value}>{children}</TabsContext.Provider>;
};
