import { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { keyBy, keys } from 'lodash';
import {
  Input,
  Textarea,
  Flex,
  Box,
  InputGroup,
  InputRightAddon,
  Text,
  Checkbox,
  Switch,
  Tooltip,
} from '@chakra-ui/react';
import AddressAutocomplete from './AddressAutocomplete';
import { useTabsContext } from '../tabs/useTabsContext';
import TreeInput, { TLeaf } from './TreeInput';
import { useGlobalContext } from '../../useGlobalContext';
import {
  formatDate,
  formatPhoneNumber,
  getReadableColor,
  useGetSelectFromId,
} from '../../helpers';
import SelectInput from './SelectInput';
import FormGroup from './FormGroup';
import { CirlceIcon, allIcons } from '../utils/Icon';
import RSelect from './RSelect';

export type TAction = {
  title?: string;
  label?: string;
  level?: number;
  isChecked?: boolean;
  onChange?: any;
  isIndeterminate?: boolean;
};

export type TField = {
  _id?: string;
  name_id?: string;
  type: string;
  name?: string;
  label: string;
  required?: boolean;
  options?: {
    label: string;
    value: any;
  }[];
  colors?: string[];
  unity?: string;
  list?: TLeaf[];
  actions?: TAction[];
  selected?: any;
  addressFields?: Record<string, boolean>;
  group?: string;
  forced?: boolean;
  condition?: (formData: Record<string, any>) => boolean;
  placeholder?: string;
};

const useForm = (
  fields: TField[] = [],
  onSubmit?: (x?: any, y?: any, z?: any) => Promise<any>,
  planable?: boolean,
  noRowData?: boolean,
) => {
  const getSelectFromId = useGetSelectFromId();
  const { modalType } = useGlobalContext() || [];
  const { dataInputs, rowData, userList, canI } = useTabsContext() || [];
  const [pending, setPending] = useState(false);

  const [formData, setFormData] = useState<Record<string, any>>(
    noRowData ? {} : rowData || {},
  );

  const isAdd = modalType === 'addEntry';

  const groupOptions = keys(keyBy(dataInputs, 'group'))
    .filter((f) => f && f !== 'undefined')
    .map((v) => ({
      label: v,
      value: v,
    }));

  useEffect(() => {
    !/planning/i.test(modalType) && setFormData({});
  }, [modalType]);

  useEffect(() => {
    !noRowData && setFormData(rowData || {});
  }, [rowData]);

  const handleSubmit = async () => {
    const resp = await onSubmit?.(formData);
    return resp;
  };

  const handleOnChange = (label: string, e: any, forced?: boolean) => {
    setFormData((d) => ({
      ...d,
      [label]: forced ? e : e ?? '',
    }));
  };

  const handleReset = (name: string) => {
    setFormData((d: any) => ({
      ...d,
      [name]: null,
      [`${name}_street`]: ``,
      [`${name}_city`]: '',
      [`${name}_dept`]: '',
      [`${name}_postal`]: '',
      [`${name}_addressDescription`]: '',
    }));
  };

  const getInput = (field: TField) => {
    const name = field.name_id || field.name || field._id || '';
    const [optionName, colorName] = name.split('-');

    const usersById: Record<string, any> = keyBy(userList, '_id');

    const isDisabled =
      /editEntry/.test(modalType) &&
      !canI(name, 'update') &&
      !canI(`${name}_street`, 'update') &&
      !canI(`${name}_city`, 'update') &&
      !canI(`${name}_postal`, 'update') &&
      !canI(`${name}_dept`, 'update');

    switch (field.type) {
      case 'users':
      case 'select':
        const options: any =
          field.name_id === 'created_by'
            ? userList.map((ul) => ({ value: ul._id, label: ul.name }))
            : field.type === 'users'
            ? field.options?.map((option) => {
                const opt = usersById[option as any] || {};
                return { value: opt._id, label: opt.name };
              })
            : typeof field.options?.[0] === 'object'
            ? field.options
            : (field.options || getSelectFromId(name)?.options)?.map(
                (option, idx) => ({
                  label: option,
                  value: idx,
                }),
              );

        const colorIndex = options?.findIndex(
          (option: any) => option.value === formData[name],
        );

        const currOption =
          formData[name] == null
            ? null
            : isNaN(formData[name]) || colorIndex >= 0
            ? options.find((option: any) => option.value === formData[name])
            : options[Number(formData[name])];

        const color =
          currOption?.color ||
          field.colors?.[colorIndex >= 0 ? colorIndex : formData[name]] ||
          null;

        return (
          <Flex alignItems='center'>
            <RSelect
              isDisabled={isDisabled}
              onChange={(e) => handleOnChange(name, e?.value)}
              value={currOption}
              options={options}
              placeholder={field.placeholder}
            />
            {color && (
              <Box
                borderRadius={5}
                ml={3}
                pr={5}
                backgroundColor={color}
                width='32px'
                height='32px'
              />
            )}
            <CirlceIcon
              ml={3}
              as={allIcons.HiMiniXMark}
              onClick={() => handleReset(name)}
            />
          </Flex>
        );
      case 'multiselect':
        try {
          const multiValue = formData[name]?.map((data: any) => ({
            value: Number(data),
            label: field.options?.[Number(data)],
            color: field.colors?.[Number(data)],
          }));

          return (
            <Flex alignItems='center'>
              <RSelect
                closeMenuOnSelect={false}
                isDisabled={isDisabled}
                isMulti
                onChange={(e) =>
                  handleOnChange(
                    name,
                    e.map((multiVal: any) => multiVal.value),
                  )
                }
                value={multiValue || []}
                options={(field.options || [])
                  .map((option, idx) => ({
                    label: option,
                    value: idx,
                  }))
                  .filter((o: any) => o.label)}
                styles={{
                  multiValueLabel: (styles, { data }) => ({
                    ...styles,
                    color: getReadableColor(data.color),
                  }),
                  multiValue: (styles, { data }) => ({
                    ...styles,
                    backgroundColor: data.color,
                  }),
                  multiValueRemove: (styles, { data }) => ({
                    ...styles,
                    color: getReadableColor(data.color),
                    ':hover': {
                      opacity: 0.5,
                    },
                  }),
                }}
              />
              <CirlceIcon
                ml={3}
                as={allIcons.HiMiniXMark}
                onClick={() => handleReset(name)}
              />
            </Flex>
          );
        } catch (e) {
          return <></>;
        }
      case 'address':
        const addr = {
          street: formData[`${name}_street`],
          city: formData[`${name}_city`],
          postal: formData[`${name}_postal`],
          id: formData[name],
        };

        return (
          <Flex flexWrap='wrap'>
            <Box w='100%'>
              <FormGroup
                field={field}
                prefixLabel='adresse'
                planable={planable}
                input={
                  <AddressAutocomplete
                    isDisabled={isDisabled}
                    setFormData={setFormData}
                    value={addr.street}
                    label={name}
                    setPending={setPending}
                    type='street'
                  />
                }
              />
            </Box>
            <Flex w='100%' gap={2} alignItems='end' flexWrap='wrap'>
              <Box w={['100%', '50%']}>
                <FormGroup
                  field={field}
                  prefixLabel='ville'
                  input={
                    <AddressAutocomplete
                      isDisabled={isDisabled}
                      setFormData={setFormData}
                      value={addr.city}
                      label={name}
                      setPending={setPending}
                      type='city'
                    />
                  }
                />
              </Box>
              <Box flexGrow={1}>
                <FormGroup
                  field={field}
                  prefixLabel='code postal'
                  input={
                    <AddressAutocomplete
                      isDisabled={isDisabled}
                      setFormData={setFormData}
                      value={addr.postal}
                      label={name}
                      setPending={setPending}
                      type='postal'
                    />
                  }
                />
              </Box>
              {!addr.id && (
                <Tooltip label='Adresse non valide'>
                  <span>
                    <CirlceIcon
                      as={allIcons.HiMiniExclamationTriangle}
                      color='orange.400'
                      mb={0.5}
                    />
                  </span>
                </Tooltip>
              )}
              <CirlceIcon
                as={allIcons.HiMiniXMark}
                onClick={() => handleReset(name)}
                mb={0.5}
              />
            </Flex>
          </Flex>
        );
      case 'tree':
        return (
          <TreeInput
            list={field.list || []}
            actions={field.actions || []}
            selected={field.selected}
            onChange={(e: any) => handleOnChange(name, e)}
          />
        );
      case 'selectInput':
        return (
          <SelectInput
            label={field.label}
            data={formData[optionName]}
            colors={formData[colorName]}
            setFormData={(options: string[], colors: string[]) => {
              handleOnChange(optionName, options);
              handleOnChange(colorName, colors);
            }}
          />
        );
      case 'multiSelect':
        return (
          <RSelect
            isDisabled={isDisabled}
            closeMenuOnSelect={false}
            defaultValue={field.options?.filter((option) =>
              rowData.options?.includes(option.value),
            )}
            isMulti
            onChange={(opts) => {
              handleOnChange(
                'options',
                opts.map((opt: any) => opt.value),
              );
            }}
            options={field.options}
          />
        );
      case 'checkbox':
        return (
          <Flex h='32px' alignItems='center'>
            <Checkbox
              isDisabled={isDisabled}
              width='24px'
              height='24px'
              isChecked={
                field.forced ? formData[name] !== undefined : formData[name]
              }
              colorScheme={
                field.forced && formData[name] === false ? 'red' : 'blue'
              }
              onChange={(e: any) =>
                handleOnChange(
                  name,
                  !field.forced
                    ? e?.target?.checked
                    : formData[name] === true
                    ? false
                    : formData[name] === false
                    ? undefined
                    : true,
                  field.forced,
                )
              }
            />
          </Flex>
        );
      case 'password':
      case 'text':
        return (
          <Input
            isDisabled={isDisabled}
            pl={2}
            h='32px'
            type={field.type}
            onChange={(e: any) => handleOnChange(name, e?.target?.value)}
            value={formData[name] ?? ''}
          />
        );
      case 'date':
        return (
          <Input
            isDisabled={isDisabled}
            pl={2}
            h='32px'
            type={field.type}
            onChange={(e: any) => handleOnChange(name, e?.target?.value)}
            value={formatDate(formData[name], true) ?? ''}
          />
        );
      case 'createSelect':
        return (
          <>
            <CreatableSelect
              isClearable
              options={groupOptions}
              defaultValue={{
                label: formData[name] ?? '',
                value: formData[name] ?? '',
              }}
              formatCreateLabel={(text) => `Ajouter ${text}`}
              onChange={(e: any) => handleOnChange(name, e?.value)}
            />
            <Text fontStyle='italic' fontSize='.8em'>
              Pour créer un nouveau groupe, taper le nom puis <b>Ajouter</b>
            </Text>
          </>
        );
      case 'tel':
        return (
          <Input
            isDisabled={isDisabled}
            pl={2}
            minH='32px'
            type={field.type}
            onChange={(e: any) => handleOnChange(name, e?.target?.value)}
            onBlur={(e: any) =>
              handleOnChange(name, formatPhoneNumber(e?.target?.value))
            }
            value={formData[name] ?? ''}
          />
        );
      case 'number':
        return (
          <InputGroup>
            <Input
              isDisabled={isDisabled}
              minH='32px'
              type='number'
              onChange={(e: any) => handleOnChange(name, e?.target?.value)}
              value={formData[name] ?? ''}
            />
            <InputRightAddon h='32px'>{field.unity}</InputRightAddon>
          </InputGroup>
        );
      case 'switch':
        return (
          <Flex alignItems='center' minH='32px'>
            <Switch
              isDisabled={isDisabled}
              alignItems='center'
              isChecked={formData[name]}
              onChange={(e: any) => handleOnChange(name, e.target.checked)}
              size='lg'
            />
          </Flex>
        );
      case 'title':
        return undefined;
      default:
        return (
          <Textarea
            isDisabled={isDisabled}
            p={2}
            minH='35px'
            maxH='100px'
            h='max-content'
            onChange={(e) => {
              e.target.style.height = 'auto'; // Reset height
              e.target.style.height = `${e.target.scrollHeight + 2}px`; // Set height to scroll height
              handleOnChange(name, e.target.value);
            }}
            value={formData[name] ?? ''}
          />
        );
    }
  };

  const getForm = (f: any) => {
    const i = getInput(f);
    return <FormGroup field={f} input={i} />;
  };

  const getFields = (f: any[] = []) =>
    f.reduce(
      (prev, c: any) =>
        c.name_id &&
        c.type !== 'title' &&
        !canI(c.name_id, isAdd ? 'create' : 'read') &&
        !canI(`${c.name_id}_street`, isAdd ? 'create' : 'read') &&
        !canI(`${c.name_id}_city`, isAdd ? 'create' : 'read') &&
        !canI(`${c.name_id}_postal`, isAdd ? 'create' : 'read') &&
        !canI(`${c.name_id}_dept`, isAdd ? 'create' : 'read')
          ? prev
          : [...prev, getForm(c)],
      [],
    ) as JSX.Element[];

  const filteredFields = fields.filter(
    (field) => !field.condition || field.condition?.(formData),
  );

  const Form = getFields(filteredFields);

  return {
    Form,
    handleSubmit,
    formData,
    setFormData,
    pending,
  };
};

export default useForm;
