import Modal from './Modal';
import useManageEntries from '../table/actions/ManageEntries';
import useManageUsers from '../table/actions/ManageUsers';
import useManageTabs from '../table/actions/ManageTabs';
import useManageFilters from '../table/actions/ManageFilters';
import useManageRoles from '../table/actions/ManageRoles';
import useManageInputs from '../table/actions/ManageInputs';
import useManageActions from '../table/actions/ManageActions';
import useManagePlanning from '../table/actions/ManagePlanning';
import useManageImport from '../table/actions/ManageImport';
import useManageDocuments from '../table/actions/ManageDocuments';

const ModalTable = () => {
  const manageTabs = useManageTabs();
  const manageEntries = useManageEntries();
  const manageUsers = useManageUsers();
  const manageFilters = useManageFilters();
  const manageRoles = useManageRoles();
  const manageInputs = useManageInputs();
  const manageActions = useManageActions();
  const managePlanning = useManagePlanning();
  const manageImport = useManageImport();
  const manageDocuments = useManageDocuments();

  const modalOptions = {
    ...manageTabs,
    ...manageEntries,
    ...manageUsers,
    ...manageFilters,
    ...manageRoles,
    ...manageInputs,
    ...manageActions,
    ...managePlanning,
    ...manageImport,
    ...manageDocuments,
  };

  return <Modal options={modalOptions} />;
};

export default ModalTable;
