import Select from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';

const cutomStyle = {
  container: (base: any) => ({
    ...base,
    fontSize: '12px',
  }),
};

const RSelect = (
  props: StateManagerProps<any, any, any> & React.RefAttributes<any>,
) => {
  return (
    <Select
      className='rSelect'
      menuPosition='fixed'
      {...props}
      styles={{ ...cutomStyle, ...props.styles }}
    />
  );
};

export default RSelect;
