import { useEffect, useState } from 'react';
import { Flex, Input } from '@chakra-ui/react';
import ColorPicker, { allColors } from './ColorPicker';
import { CirlceIcon, allIcons } from '../utils/Icon';
import { getRandom } from '../../helpers';

type TSelectInput = {
  label: string;
  setFormData: any;
  data?: string[];
  colors?: string[];
};

const emptyValueAtIndex = (v: any[], idx: number) => {
  const newArray = [...v];
  newArray.splice(idx, 1, '');
  return newArray;
};

const SelectInput = ({
  label,
  data = [],
  colors: colorsData = [],
  setFormData,
}: TSelectInput) => {
  const [values, setValues] = useState<string[]>(data);
  const [colors, setColors] = useState(colorsData);

  useEffect(() => {
    setFormData(values, colors);
  }, [values, colors]);

  return (
    <>
      {values?.map((value, idx) =>
        !value && !colors[idx] ? null : (
          <Flex alignItems='center' key={idx}>
            <Input
              key={`${idx}-${label}`}
              type='text'
              value={value}
              mt={1}
              mr={2}
              onChange={(e) =>
                setValues((v) => {
                  const newV = [...v];
                  newV[idx] = e.target.value;
                  return newV;
                })
              }
              flex={1}
            />
            <ColorPicker
              bg={colors?.[idx] || 'transparent'}
              onChange={(e) =>
                setColors((c) => {
                  const newColors = [...(c || [])];
                  newColors[idx] = e.hex;
                  return newColors;
                })
              }
            />
            <CirlceIcon
              ml={3}
              as={allIcons.HiTrash}
              onClick={() => {
                setValues((v) => emptyValueAtIndex(v, idx));
                setColors((v) => emptyValueAtIndex(v, idx));
              }}
            />
          </Flex>
        ),
      )}
      <CirlceIcon
        my={3}
        as={allIcons.HiPlusSmall}
        onClick={() => {
          setValues((v) => [...(v || []), '']);
          setColors((v) => [...(v || []), getRandom(allColors)]);
        }}
      />
    </>
  );
};

export default SelectInput;
