import { Tooltip } from '@chakra-ui/react';
import { useGlobalContext } from '../../useGlobalContext';
import { useTabsContext } from './useTabsContext';
import { allIcons, CirlceIcon } from '../utils/Icon';

const matchKey: Record<string, string> = {
  users: 'addUser',
  roles: 'addRole',
  inputs: 'addInput',
  documents: 'addDocument',
};

const ActionButtons = () => {
  const { setModalType, isEditMode, customMenuSelected } = useGlobalContext();
  const { tabToUpdate, currentTab, canI } = useTabsContext();

  const customMode =
    customMenuSelected === 'settings' ? matchKey[tabToUpdate?._id || ''] : '';

  const modalType = customMode || (currentTab?._id && 'addEntry') || '';

  const isVisible = canI(currentTab?._id || '', 'create');

  return modalType && isVisible && !isEditMode && tabToUpdate ? (
    <Tooltip label='Ajouter'>
      <span>
        <CirlceIcon
          as={allIcons.HiDocumentPlus}
          mr={2}
          onClick={() => setModalType(modalType)}
        />
      </span>
    </Tooltip>
  ) : null;
};

export default ActionButtons;
