import { Box, Flex } from '@chakra-ui/react';
import ModalTable from '../modal/ModalTable';
import DynamicTable from '../table/DynamicTable';
import SettingsTabs from '../tabs/SettingsTabs';
import SubTabsWithOrder from '../tabs/SubTabsWithOrder';
import { useGlobalContext } from '../../useGlobalContext';
import { useTabsContext } from '../tabs/useTabsContext';
import Toolbar from './Toolbar';
import Calendar from '../calendar/Calendar';
import { theme } from '../../theme';

const Main = () => {
  const { isEditMode, customMenuSelected } = useGlobalContext();
  const { ready } = useTabsContext();

  return (
    <Flex
      flexDirection='column'
      flexGrow={1}
      w={0}
      px={theme.margin}
      my={['16px', '24px']}
      overflow='hidden'
    >
      <Toolbar />
      <Box flexGrow={1} h='100%'>
        {!ready ? null : (
          <>
            <Flex
              position='relative'
              zIndex={0}
              overflow='hidden'
              id='mainContainer'
              h='100%'
              flexDirection='column'
            >
              {customMenuSelected ? null : isEditMode ? (
                <SubTabsWithOrder />
              ) : (
                <DynamicTable />
              )}
              {!isEditMode && customMenuSelected === 'settings' && (
                <SettingsTabs />
              )}
              {customMenuSelected === 'planning' &&
                (isEditMode ? <SubTabsWithOrder /> : <Calendar />)}
              {customMenuSelected === 'duplicate' &&
                (isEditMode ? <SubTabsWithOrder /> : <DynamicTable />)}
            </Flex>
            <ModalTable />
          </>
        )}
      </Box>
    </Flex>
  );
};

export default Main;
