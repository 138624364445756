import React, { useEffect, useState } from 'react';
import { useTabsContext } from '../tabs/useTabsContext';
import { Input, Flex } from '@chakra-ui/react';
import { useGlobalContext } from '../../useGlobalContext';
import * as XLSX from 'xlsx';
import { strNomalize } from '../../helpers';
import { allIcons, CirlceIcon } from '../utils/Icon';

const UploadComponent = ({
  multiple,
  onUpload,
  disabled,
}: {
  multiple?: boolean;
  onUpload?: (file: FileList) => void;
  disabled?: boolean;
}) => {
  const { setRowData } = useTabsContext();
  const { setModalType, setModalData } = useGlobalContext();

  const [file, setFile] = useState<File | FileList | null>(null);
  const [data, setData] = useState<any[] | null>(null);

  const fileInputRef = React.useRef<any>(null);

  const handleButtonClick = () => {
    setFile(null);
    fileInputRef.current.value = '';
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(multiple ? event.target.files : event.target.files[0]);
    }
  };

  const reader = new FileReader();
  reader.onload = (event) => {
    const binaryStr = event.target?.result;

    // Conversion du fichier Excel en objet binaire
    const workbook = XLSX.read(binaryStr, { type: 'binary' });

    // Sélectionner la première feuille de calcul
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];

    // Convertir la feuille en JSON
    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

    // Effectuer un prétraitement ici (par exemple, filtrer certaines données, les transformer, etc.)
    // const preProcessedData = preProcessData(jsonData);

    // Mettre à jour l'état avec les données prétraitées
    setData(jsonData.splice(0, 2));
  };

  useEffect(() => {
    if (multiple) {
      file && onUpload?.(file as FileList);
    } else {
      file && reader.readAsArrayBuffer(file as File);
    }
  }, [file]);

  useEffect(() => {
    if (!data) return;

    setModalData({ data, file });

    if (!multiple) {
      setRowData(
        data[0]?.reduce(
          (prev: Record<string, string>, header: string) => ({
            ...prev,
            [strNomalize(header)]: header,
          }),
          {},
        ),
      );

      setModalType('computeBeforeUpload');
    }
  }, [data]);

  return (
    <>
      <Input
        isDisabled={disabled}
        ref={fileInputRef}
        type='file'
        onChange={handleFileChange}
        display='none'
        multiple={!!multiple}
      />
      <Flex alignItems='center'>
        <CirlceIcon
          as={allIcons.HiInboxArrowDown}
          onClick={disabled ? () => {} : handleButtonClick}
          mr={2}
        />
      </Flex>
    </>
  );
};

export default UploadComponent;
