export const generateGoogleMapsURL = (address: string) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        const origin = `${latitude},${longitude}`;
        const url = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${encodeURIComponent(
          address,
        )}&travelmode=driving`;

        // You can set the window location to this URL or use it elsewhere in your app
        window.open(url, '_blank');
      },
      (error) => {
        console.error('Error obtaining geolocation: ', error);
      },
    );
  } else {
    console.error('Geolocation is not supported by this browser.');
  }
};
