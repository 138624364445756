import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { getUserRole } from './api/tabsApi';
import { getAppointments } from './api/planningApi';

const getUserRoleContext = async (callback: any) => {
  const resp = await getUserRole();
  callback(resp || {});
};

const MAX_MOBILE_SIZE = 480;
const MAX_TABLET_SIZE = 1200;

const useGlobal = () => {
  const disclosure = useDisclosure();
  const { isOpen, onOpen, onClose } = disclosure;
  const { isOpen: isHovered, onToggle, onOpen: onNavOpen } = useDisclosure();
  const { isOpen: filterActive, onToggle: toggleFilter } = useDisclosure();

  const localToken = localStorage.getItem('token') || '';

  const [modalType, setModalType] = useState('');
  const [modalData, setModalData] = useState<any>();
  const [token, setUserToken] = useState(localToken);
  const [isEditMode, setIsEditMode] = useState(false);
  const [customMenuSelected, setCustomMenuSelected] = useState('');
  const [userRole, setUserRole] = useState<Record<string, string[]>>({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuper, setIsSuper] = useState(false);
  const [user, setUser] = useState<Record<string, any>>({});
  const [login, setLogin] = useState('');
  const [showNbLine, setShowNbLine] = useState(true);
  const [isPlanning, setIsPlanning] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [planningAddress, setPlanningAddress] = useState('');
  const [isPlanningEdit, setIsPlanningEdit] = useState(false);
  const [appointments, setAppointments] = useState<any>({});
  const [darkLight, setDarkLight] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < MAX_MOBILE_SIZE);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth >= MAX_MOBILE_SIZE && window.innerWidth < MAX_TABLET_SIZE,
  );

  useEffect(() => {
    localStorage.setItem('company', window.location.pathname.split('/')[1]);
    window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth < MAX_MOBILE_SIZE);
      setIsTablet(
        window.innerWidth >= MAX_MOBILE_SIZE &&
          window.innerWidth < MAX_TABLET_SIZE,
      );
    });
  }, []);

  useEffect(() => {
    if (modalType) {
      onOpen();
    }
  }, [modalType]);

  useEffect(() => {
    if (!isOpen) {
      setModalType('');
      setPlanningAddress('');
    }
  }, [isOpen]);

  useEffect(() => {
    token &&
      !login &&
      getUserRoleContext((resp: any) => {
        setUserRole(resp.data || []);
        setIsAdmin(!!resp.isAdmin);
        setIsSuper(!!resp.isSuper);
        setIsPlanning(!!resp.isPlanning);
        setIsDuplicate(!!resp.isDuplicate);
        setLogin(resp.login);

        setUser(resp);
      });
  }, [token, login]);

  // useEffect(() => {
  //   isEditMode && localStorage.removeItem('currentTab');
  // }, [isEditMode]);

  const setToken = (t: string) => {
    if (!t) {
      localStorage.removeItem('currentTab');
    }

    localStorage.setItem('token', t);
    setUserToken(t);
  };

  const fetchAppointments = useCallback(async () => {
    const resp = await getAppointments();
    setAppointments(resp.data);
  }, []);

  const value = useMemo(
    () => ({
      modalType,
      isOpen,
      token,
      isEditMode,
      filterActive,
      customMenuSelected,
      userRole,
      disclosure,
      isAdmin,
      isSuper,
      login,
      isHovered,
      showNbLine,
      isPlanning,
      isDuplicate,
      planningAddress,
      isPlanningEdit,
      appointments,
      modalData,
      user,
      darkLight,
      isMobile,
      isTablet,
      setDarkLight,
      setUser,
      setLogin,
      setModalData,
      fetchAppointments,
      setIsPlanningEdit,
      setPlanningAddress,
      setShowNbLine,
      onNavOpen,
      onToggle,
      setCustomMenuSelected,
      toggleFilter,
      toggleEditMode: () => setIsEditMode(!isEditMode),
      setToken,
      setModalType,
      onOpen,
      onClose,
    }),
    [
      isMobile,
      isTablet,
      isPlanning,
      isDuplicate,
      customMenuSelected,
      filterActive,
      isEditMode,
      token,
      modalType,
      disclosure,
      userRole,
      isAdmin,
      isSuper,
      login,
      isHovered,
      showNbLine,
      planningAddress,
      isPlanningEdit,
      appointments,
      modalData,
      darkLight,
    ],
  );

  return value;
};

const GlobalContext = createContext({} as ReturnType<typeof useGlobal>);

export const useGlobalContext = () => useContext(GlobalContext);

export const GlobalProvider = ({ children }: { children: ReactNode }) => {
  const value = useGlobal();

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};
